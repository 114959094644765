var util = {};
util.levelArr = [0, 1, 2, 3];

util.oneOf = function (item, arr) {
  return arr.some(function (i) {
    return i === item;
  });
};

util.getIndex = function (list, name) {
  for (var i in list) {
    if (list[i] === name) {
      return i;
    }
  }
};

util.dataType = ['all', 'code', 'name'];

util.checkLevel = function (val) {
  return util.oneOf(val, util.levelArr);
};

export default util;