import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
//
//
//
//
//
//
//
//
//
//
//
//
// import pca from 'area-data/pcaa'
import { pca, pcaa } from 'area-data';
import util from "./util";

var levelShow = function levelShow(level, code) {
  if (level === 2) {
    return Object.keys(pcaa['86']).indexOf(code) > -1;
  } else if (level === 3) {
    return true;
  }
};

export default {
  props: {
    value: Array,
    placeholder: String,
    level: {
      type: [Number, String],
      default: 3,
      validator: function validator(val) {
        return util.checkLevel(parseInt(val));
      }
    },
    dataType: {
      type: String,
      default: 'all',
      validator: function validator(val) {
        return util.oneOf(val, util.dataType);
      }
    }
  },
  data: function data() {
    return {
      data: [],
      select: [],
      isInit: true,
      oldData: []
    };
  },
  methods: {
    init: function init() {
      var proData = [];
      console.log(pcaa['86']);

      for (var p in pcaa['86']) {
        var proitem = {
          value: p,
          label: pcaa['86'][p],
          children: []
        };

        if (this.showLevel > 0 && pcaa[p]) {
          proitem.loading = false;
        }

        proData.push(proitem);
      }

      console.log(proData, 'proData');
      this.data = proData;
    },
    setDefaultValue: function setDefaultValue() {
      console.log('setvAL');

      if (this.value[0]) {
        console.log('ahdald');
        var select = [];

        if (isNaN(parseInt(this.value[0]))) {
          var i = 0;
          var index = '';

          while (this.value[i] && i <= this.showLevel) {
            if (i === 0) {
              index = util.getIndex(pcaa['86'], this.value[0]);
            } else {
              index = util.getIndex(pcaa[index], this.value[i]);
            }

            select.push(index);
            i++;
          }

          this.select = select;
        } else {
          this.select = this.value;
        }

        var res = this.procesValue(this.select);
        this.oldData = res;
        this.$emit('input', res);
        this.$emit('on-change', res);
      }
    },
    canEmit: function canEmit(res) {
      var ifEmit = false;

      if (this.value && this.value.length !== 0) {
        if (typeof res[0] === 'string') {
          if (this.value[this.value.length - 1] !== this.oldData[this.oldData.length - 1]) {
            ifEmit = true;
          }
        } else {
          if (this.oldData && this.oldData.length !== 0 && this.value[this.value.length - 1].code !== this.oldData[this.oldData.length - 1].code) {
            ifEmit = true;
          }
        }
      } else {
        ifEmit = true;
      }

      return ifEmit;
    },
    procesValue: function procesValue(arr) {
      var i = 0;
      var res = [];

      while (arr[i]) {
        var name = '';

        if (i === 0) {
          name = pcaa['86'][arr[i]];
        } else {
          name = pcaa[arr[i - 1]][arr[i]];
        }

        var item = void 0;

        if (this.dataType === 'all') {
          item = {
            code: arr[i],
            name: name
          };
        } else if (this.dataType === 'name') {
          item = name;
        } else {
          item = arr[i];
        }

        res.push(item);
        i++;
      }

      return res;
    },
    handleChange: function handleChange(resArr) {
      console.log(resArr, 'RESaRR');
      var res = this.procesValue(resArr);
      this.oldData = res;
      this.$emit('input', res);
      this.$emit('change', res);
    }
  },
  mounted: function mounted() {
    console.log('hahah');
    this.init();

    if (this.canEmit(this.value)) {
      console.log('dddd');
      this.setDefaultValue();
    }
  },
  computed: {
    showLevel: function showLevel() {
      return parseInt(this.level);
    }
  },
  watch: {
    value: function value(val) {
      if (this.canEmit(this.value)) {
        this.setDefaultValue();
      }
    }
  }
};