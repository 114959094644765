var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-cascader", {
        attrs: {
          options: _vm.data,
          placeholder: _vm.placeholder,
          clearable: ""
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.select,
          callback: function($$v) {
            _vm.select = $$v
          },
          expression: "select"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }