export function portList() {
  var arr = [];

  for (var i = 1; i < 21; i++) {
    var obj = {};
    obj['label'] = i;
    obj['value'] = i;
    arr.push(obj);
  }

  return arr;
}
export function transChargeType(type) {
  switch (type) {
    case 0:
      return '自行车充电桩';

    case 1:
      return '汽车充电桩';

    case 3:
      return '电动车充电柜';

    case 4:
      return '二合一';

    case 5:
      return '智能充电插座';

    default:
      return '未知';
  }
}
export function transMemberLevel(type) {
  switch (type) {
    case 0:
      return '普通';

    case 1:
      return '银钻';

    case 2:
      return '金钻';

    default:
      return '未知';
  }
}
export function transOnline(type) {
  switch (type) {
    case 1:
      return '在线';

    case 2:
      return '离线';

    default:
      return '未知';
  }
} // 禁启

export function transBanKai(type) {
  switch (type) {
    case 0:
      return '禁用';

    case 1:
      return '启动';

    default:
      return '未知';
  }
}
export function transDeviceState(type) {
  switch (type) {
    case 0:
      return '无故障';

    case 1:
      return '过压';

    case 2:
      return '欠压';

    case 3:
      return '过流';

    case 4:
      return '过温';

    case 5:
      return '烟雾';

    case 6:
      return '火警';

    case 7:
      return '漏电（一级故障）';

    case 8:
      return '短路（一级故障）';

    case 9:
      return '接地故障';

    default:
      return '未知';
  }
}
export function transBindState(type) {
  switch (type) {
    case 1:
      return '未绑定';

    case 2:
      return '已绑定';

    case 3:
      return '未制卡';

    case 4:
      return '已挂失';

    default:
      return '未知';
  }
}
export function transPayState(type) {
  switch (type) {
    case 0:
      return '待支付';

    case 1:
      return '发起充电';

    case 2:
      return '正在充电';

    case 3:
      return '充电完成';

    case 4:
      return '充电已暂停';

    case 5:
      return '已退款';

    case 99:
      return '充电失败';

    default:
      return '未知';
  }
}
export function transSourceType(type) {
  switch (type) {
    case 0:
      return '未知';

    case 1:
      return '后台';

    case 2:
      return '微信';

    case 3:
      return 'app';

    default:
      return '未知';
  }
}
export function transRechargeType(type) {
  switch (type) {
    case 0:
      return '其他';

    case 1:
      return '充值';

    case 2:
      return '消费';

    case 3:
      return '扣款';

    case 4:
      return '退款';

    case 5:
      return '云闪付充值';

    default:
      return '未知';
  }
}
export function transChannel(type) {
  switch (type) {
    case 0:
      return '其他';

    case 1:
      return '微信';

    case 2:
      return '支付宝';

    case 3:
      return '线下';

    case 4:
      return '后台';

    case 5:
      return '云闪付充值';

    default:
      return '未知';
  }
}
export function transChannelSvg(type) {
  switch (type) {
    case 1:
      return 'wechat';

    case 2:
      return 'zfb';

    default:
      return 'backstage';
  }
} // 优惠活动

export function transAcType(type) {
  switch (type) {
    case 0:
      return '未开始';

    case 1:
      return '进行中';

    case 2:
      return '已结束';

    default:
      return '未知';
  }
}
export function transCommonState(type) {
  switch (type) {
    case 1:
      return '成功';

    case 2:
      return '失败';

    default:
      return '未知';
  }
}
export function transSelectState(type) {
  switch (type) {
    case 1:
      return '代理商';

    case 2:
      return '小区';

    default:
      return '全部';
  }
}
export function transBillState(type) {
  switch (type) {
    case 0:
      return '未结算';

    case 1:
      return '已结算';

    default:
      return '全部';
  }
} // 等级

export function transUserLevel(type) {
  var numberCN = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

  if (numberCN[type]) {
    return numberCN[type] + '级代理';
  } else {
    return '超出十级代理';
  }
}
export function downloadFun(href, fileName) {
  // 下载方法
  var doc = document.createElement('a');
  doc.href = href;
  doc.download = fileName;
  doc.target = '_blank';
  var event = document.createEvent('MouseEvent');
  event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  doc.dispatchEvent(event);
} // 

export function downloadBlob(data, name) {
  var content = "\uFEFF" + data;
  var blob = new Blob([content]);
  var fileName = name;

  if ('download' in document.createElement('a')) {
    // 非IE下载
    var elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象

    document.body.removeChild(elink);
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName);
  }
}
export function orderStopCode(code) {
  switch (code) {
    case 0:
      return "自动充满";

    case 1:
      return "达到设定时间";

    case 2:
      return "达到设定电量";

    case 3:
      return "达到设定金额";

    case 4:
      return "触摸屏通讯故障";

    case 5:
      return "电能表通讯故障";

    case 6:
      return "连接确认异常";

    case 7:
      return "输入过压";

    case 8:
      return "输入欠压";

    case 9:
      return "输出过压";

    case 10:
      return "输出欠压";

    case 11:
      return "PWM电压异常";

    case 12:
      return "急停拍下";

    case 13:
      return "输出过流";

    case 14:
      return "防雷器故障";

    case 15:
      return "充电监控通讯故障";

    case 16:
      return "直流母线过压";

    case 17:
      return "直流母线绝缘下降";

    case 18:
      return "单体电池过压";

    case 19:
      return "单体电池过温";

    case 20:
      return "风扇故障";

    case 21:
      return "系统过温";

    case 22:
      return "BMS通讯故障";

    case 23:
      return "接地故障";

    case 24:
      return "电池反接";

    case 25:
      return "电池电压和报文上送不一致";

    case 26:
      return "车允许充电超时(10min)";

    case 27:
      return "输出电压大于最高允许充电电压";

    case 28:
      return "电池状态不正常结束充电";

    case 29:
      return "开始预充过程收到了BMS未准备就绪报文BRO(00)";

    case 30:
      return "电子锁未锁住";

    case 31:
      return "主接触器故障";

    case 32:
      return "门禁告警停止";

    case 33:
      return "充电模块故障";

    case 34:
      return "充电电流过小";

    case 35:
      return "余额不足";

    case 36:
      return "人为主动停止";

    case 37:
      return "电表电能异常值";

    case 38:
      return "系统断电";

    case 39:
      return "网络断线";

    case 40:
      return "充电枪过温";

    case 41:
      return "单体蓄电池电压过低";

    case 42:
      return "整车蓄电池SOC过高";

    case 43:
      return "整车蓄电池SOC过低";

    case 44:
      return "动力蓄电池充电过流";

    case 45:
      return "动力蓄电池绝缘异常";

    case 46:
      return "动力蓄电池组输出连接器异常";

    case 47:
      return "BMS达到设定SOC";

    case 48:
      return "BMS达到设定总电压";

    case 49:
      return "BMS达到单体电压设定值";

    case 50:
      return "BMS绝缘异常";

    case 51:
      return "BMS连接器过温";

    case 52:
      return "BMS元件过温";

    case 53:
      return "BMS连接器故障";

    case 54:
      return "BMS电池组过温";

    case 55:
      return "BMS高压继电器故障";

    case 56:
      return "BMS监测点2电压异常";

    case 57:
      return "BMS其它故障";

    case 58:
      return "BMS报电流异常的停止充电";

    case 59:
      return "BMS报电压异常的停止充电";

    case 60:
      return "车辆最高允许充电总电压不匹配(小于模块最小输出电压)";

    case 61:
      return "充电需求大于蓄电池参数(BCP最大允许充电电压、电流参数)";

    case 62:
      return "刷卡停止";

    case 63:
      return "屏幕停止";

    case 64:
      return "其他方式停止";

    case 65:
      return "BRM报文超时";

    case 66:
      return "BCP报文超时";

    case 67:
      return "BRO报文超时";

    case 68:
      return "BCS报文超时";

    case 69:
      return "BCL报文超时";

    case 70:
      return "BST报文超时";

    case 71:
      return "BSD报文超时";

    case 72:
      return "其他报文超时";
  }
}
export function controlState(state) {
  switch (state) {
    case 0:
      return "测试";

    case 1:
      return "库存";

    case 2:
      return "安装";

    case 3:
      return "运营";

    case 4:
      return "返厂";

    case 5:
      return "废弃";

    default:
      return "未知";
  }
}